import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from 'axios';
import {initialStateUser} from 'redux/Constant/InitialState';
import {AppThunk, RootState} from 'redux/Store/Store';
import {toggleLoading} from 'redux/Reducer/Loading/LoadingSlice';
import {types} from 'redux/Types/Types';
import {UriConfig} from 'config';
//Interface
import {IUser} from 'interface/Interface';

const api_rest = UriConfig['api_sql_url'];

//Negocio
export const getAllUsers = (): AppThunk => async (dispatch) => {
  try {
    dispatch(toggleLoading());
    await axios
      .get(`${api_rest}/api/auth-role`)
      .then((response) => {
        dispatch(setUserListReducer(response.data));
        dispatch(toggleLoading());
      })
      .catch((err) => {
        dispatch(toggleLoading());
        console.log('Error::', err);
      });
  } catch (err) {
    dispatch(toggleLoading());
    console.log('Error::', err);
  }
};

export const getUsers =
  (mail: string): AppThunk =>
  async (dispatch) => {
    try {
      console.log('mail::', mail);
      dispatch(toggleLoading());
      await axios
        .get(`${api_rest}/api/auth-role/${mail}`, {
          params: {
            id: mail,
          },
        })
        .then((response) => {
          dispatch(getUserReducer(response.data));
          console.log('response.data::', response.data);
          dispatch(toggleLoading());
        })
        .catch((err) => {
          dispatch(toggleLoading());
          console.log('Error::', err);
        });
    } catch (err) {
      dispatch(toggleLoading());
      console.log('Error::', err);
    }
  };

export const cleanUsers = () => (dispatch: any) => {
  console.log('cleanUsers:::', cleanUsers);
  dispatch(cleanUserListReducer());
};

export const userSlice = createSlice({
  name: types.userType,
  initialState: {
    ...initialStateUser,
  },
  reducers: {
    setUserListReducer: (state: any, action: PayloadAction<any>) => {
      state.listAllUser = action.payload || [];
    },
    getUserReducer: (state: any, action: PayloadAction<IUser>) => {
      state.dataUser = action.payload;
    },
    cleanUserListReducer: (state: any = initialStateUser) => {
      state.listAllUser = [];
    },
  },
});

//Action
export const {setUserListReducer, getUserReducer, cleanUserListReducer} = userSlice.actions;

//State-Reducer
export const selectUsers = (state: RootState) => state.user;
//Reducer
export default userSlice.reducer;
