import {createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import {initialStateShortening, initialStateSetSnackbar} from 'redux/Constant/InitialState';
import {RootState} from 'redux/Store/Store';
import {toggleLoading} from 'redux/Reducer/Loading/LoadingSlice';
import {setOpenSnackbar} from 'redux/Reducer/Snackbar/SnackbarSlice';
import {types} from 'redux/Types/Types';
import {errorColor, successColor} from 'components/Middleware/Feedback/Snackbar';
import {UriConfig} from 'config';
import {ISnackbar} from 'interface/Interface';

const api_rest = UriConfig['api_sql_url'];

//Negocio
export const getAllCountries = () => (dispatch) => {
  const token = localStorage.getItem('token');
  const headerConfig = {headers: {
    Authorization: 'Bearer ' + token //the token is a variable which holds the token
  }}

  axios
    .get(`${api_rest}/api/generic/country`, headerConfig)
    .then((response) => {
      dispatch(setShorteningCountryList(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getBusinessUnitByCountry = (idCountry: string) => (dispatch: any) => {
  axios
    .get(`${api_rest}/api/factor/filter`, {
      params: {
        idCountry: idCountry,
      },
    })
    .then((response) => {
      dispatch(setShorteningBusinessUnitList(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getBranchOfficeByBusinessUnit = (idCountry: string, idBusinessUnit: string) => (dispatch: any) => {
  dispatch(toggleLoading());
  axios
    .get(`${api_rest}/api/factor/filter`, {
      params: {
        idCountry: idCountry,
        idBusinessUnit: idBusinessUnit,
      },
    })
    .then((response) => {
      dispatch(setShorteningBranchOfficeList(response.data));
      dispatch(toggleLoading());
    })
    .catch(function (error) {
      dispatch(toggleLoading());
      console.log(error);
    });
};

export const getClacomDataByParameters =
  (idCountry: string, idBusinessUnit: string, idBranchOffice: string) => (dispatch: any) => {
    dispatch(toggleLoading());
    axios
      .get(`${api_rest}/api/factor/filter`, {
        params: {
          idCountry: idCountry,
          idBusinessUnit: idBusinessUnit,
          idBranchOffice: idBranchOffice,
        },
      })
      .then((response) => {
        dispatch(setShorteningClacomList(response.data));
        dispatch(toggleLoading());
      })
      .catch(function (error) {
        dispatch(toggleLoading());
        console.log(error);
      });
  };

export const getResumeDataByParameters = (filterObject: any) => (dispatch: any) => {
  dispatch(toggleLoading());
  axios
    .get(`${api_rest}/api/factor/provision-resume`, {
      params: {
        country: filterObject.country,
        businessUnit: filterObject.businessUnit,
        branchOffice: filterObject.branchOffice,
        date: filterObject.date,
      },
    })
    .then((response) => {
      let objSnackbar: ISnackbar = initialStateSetSnackbar;
      const newArrayFormatted: any = setteringData(response.data);
      if (newArrayFormatted.length === 0) {
        objSnackbar = {
          isOpen: true,
          message: 'No se encontraron datos',
          severity: errorColor,
          timeOut: initialStateSetSnackbar.timeOut,
          anchorOrigin: initialStateSetSnackbar.anchorOrigin,
        };
      } else {
        objSnackbar = {
          isOpen: true,
          message: 'Resultado satisfatorio',
          severity: successColor,
          timeOut: initialStateSetSnackbar.timeOut,
          anchorOrigin: initialStateSetSnackbar.anchorOrigin,
        };
      }
      dispatch(setShorteningResumeList(newArrayFormatted));
      dispatch(setOpenSnackbar(objSnackbar));
      dispatch(toggleLoading());
    })
    .catch(function (error) {
      dispatch(toggleLoading());
      console.log('Error::', error);
    });
};

const setteringData = (response: any) => {
  // console.log('%c Respuesta desde BD', 'color: green', response);
  let newArrayResumeData: any[] = [];
  response.group_list?.forEach((element: any, index) => {
    // const data = response.group_list.find((x: any) => x.PrvClaCom === element.PrvClaCom);
    let objTemp = {
      id: index + 1,
      date: element.PrvtrxTimeStamp,
      branchOffice: response.branchOffice,
      clacom: element.PrvClaCom,
      saleAmount: new Intl.NumberFormat('de-DE').format(element.PrvSalePriceNet),
      // prvAmount: new Intl.NumberFormat('de-DE').format(data.PrvCostUnitAmount) || 0,
      prvAmount: new Intl.NumberFormat('de-DE').format(element.PrvCostUnitAmount) || 0,
    };
    newArrayResumeData.push(objTemp);
  });
  return newArrayResumeData;
};

//Reducers
export const shorteningSlice = createSlice({
  name: types.shortType,
  initialState: {
    ...initialStateShortening,
  },
  reducers: {
    setShorteningCountryList: (state: any = initialStateShortening, action: any) => {
      state.listShorteningCountry = action.payload || [];
    },
    setShorteningBusinessUnitList: (state: any = initialStateShortening, action: any) => {
      state.listShorteningBusinessUnit = action.payload || [];
    },
    setShorteningBranchOfficeList: (state: any = initialStateShortening, action: any) => {
      state.listShorteningBranchOffice = action.payload || [];
    },
    setShorteningClacomList: (state: any = initialStateShortening, action: any) => {
      state.listShorteningClacomData = action.payload || [];
    },
    /* Shortening Resume */
    setShorteningResumeList: (state: any = initialStateShortening, action: any) => {
      state.listShorteningResumeData = action.payload || [];
    },
  },
});

//Action
export const {
  setShorteningCountryList,
  setShorteningBusinessUnitList,
  setShorteningBranchOfficeList,
  setShorteningClacomList,
  setShorteningResumeList,
} = shorteningSlice.actions;

//State-Reducer
export const selectShortening = (state: RootState) => state.shortening;
//Expo. Reducer
export default shorteningSlice.reducer;
