import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
//Slices in Reducer
import shorteningSlice from 'redux/Reducer/Shortening/ShorteningSlice';
import paramSlice from 'redux/Reducer/Parameter/ParamSlice';
import devolutionSlice from 'redux/Reducer/Devolution/DevolutionSlice';
import userSlice from 'redux/Reducer/User/UserSlice';
import snackbarSlice from 'redux/Reducer/Snackbar/SnackbarSlice';
import loadingSlice from 'redux/Reducer/Loading/LoadingSlice';
import authSlice from 'redux/Reducer/Authentication/AuthSlice';

export const store = configureStore({
  reducer: {
    user: userSlice,
    shortening: shorteningSlice,
    parameter: paramSlice,
    devolution: devolutionSlice,
    snackbar: snackbarSlice,
    loading: loadingSlice,
    auth: authSlice,
  },
});
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
