import {Suspense, lazy} from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import AuthGuard from 'guards/AuthGuard'; //Guards
import BackdropSimple from 'components/Middleware/Loader/BackdropSimple'; //Loading
import RolesRoute from './RolesRoute'; //Role
import {PathConfig, RoleConfig} from 'config'; //Const Config

const Routepath = () => {
  //Components
  //const Auth = lazy(() => import('components/Auth/AuthAccess'));
  const Home = lazy(() => import('components/Home/Home'));
  const Maintainers = lazy(() => import('components/Maintainers/Maintainers'));
  const RoleMaintainer = lazy(() => import('components/RoleMaintainer/RoleMaintainer'));
  const UserMaintainer = lazy(() => import('components/UserMaintainer/UserMaintainer'));
  const UserProfile = lazy(() => import('components/UserProfile/UserProfile'));
  const Factors = lazy(() => import('components/Shortening/Factors/Factors'));
  const Resume = lazy(() => import('components/Shortening/Resume/Resume'));
  const DevolutionBalance = lazy(() => import('components/DevolutionBalance/DevolutionBalance'));
  const DevolutionSummary = lazy(() => import('components/DevolutionSummary/DevolutionSummary'));
  const Obsolescence = lazy(() => import('components/Obsolescence/Obsolescence'));

  const DetailClacom = lazy(() => import('components/Clacoms/Factors/FactorsClacom'));

  const Clacoms = lazy(() => import('components/Clacoms/Clacoms'));

  //Pages
  const NotMatch = lazy(() => import('pages/NotMatch'));
  // const LogOut = lazy(() => import('router/LogOut'));
  //Path


  const loginPath = PathConfig['patLogin']; //replace Auth Login
  const homePath = PathConfig['patHome'];
  const mainUserPath = PathConfig['patMaintainerUser'];
  const mainRolePath = PathConfig['patMaintainerRole'];
  const maintsPath = PathConfig['patMaintainers'];
  const profilePath = PathConfig['patProfile'];
  const shortenFilterPath = PathConfig['patShorteningCriterion'];
  const shortenResumePath = PathConfig['patShorteningResume'];
  const devolutionBalancePath = PathConfig['patDevolutionBalance'];
  const devolutionSummaryPath = PathConfig['patDevolutionSummary'];
  const obsolescencePath = PathConfig['patObsolescenceCriterion'];
  const clacomPath = PathConfig['patClacoms'];
  const clacomDetailsPath = PathConfig['patDetailClacom'];


  return (
    <>
      <Suspense fallback={<BackdropSimple />}>
        <Routes>
          {/* <Route path="/" element={<Navigate to={devolutionBalancePath} replace />} /> */}

          <Route path="/">
            
            <Route path={devolutionBalancePath} element={<AuthGuard children={<DevolutionBalance />} />} />
            <Route path={devolutionSummaryPath} element={<AuthGuard children={<DevolutionSummary />} />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};

export default Routepath;
