const localhost = window.location.hostname.indexOf('localhost') >= 0;
const qa = window.location.hostname.indexOf('qa') >= 0;

var Uri
if (qa || localhost) {
  Uri = {
    MAX_ATTACHMENT_SIZE: 5000000,
    api_sql_url:process.env.REACT_APP_BFF_URL,
    exclude_country: process.env.EXCLUDE_COUNTRY ?? "CO"
,
  };
} else {
  Uri = {
    MAX_ATTACHMENT_SIZE: 5000000,
    api_sql_url:process.env.REACT_APP_BFF_URL_PROD ?? "https://bff-prv.falabella.com/bff-prv-front",
    exclude_country: process.env.EXCLUDE_COUNTRY ?? "CO",
  };
}
console.log(Uri)


export default Uri;
