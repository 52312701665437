const Path = {
  patLogin: '/auth/login',
  patRedirectHome: '../../home',
  patHome: '/home',
  patShorteningCriterion: '/factors',
  patShorteningResume: '/resume',
  patObsolescenceCriterion: '/obsolescence',
  patMaintainerUser: '/user',
  patMaintainerRole: '/role',
  patMaintainers: '/maintainers',
  patProfile: '/user/profile',
  patDevolutionBalance: '/',
  patDevolutionSummary: '/summary',
  patClacoms: '/clacoms',
  patDetailClacom: '/clacoms/:id/:code',
};
export default Path;
