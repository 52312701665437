export function formatNumberToClpMoney(num: any) {
  let result: string = '0';
  if (num !== undefined)
    result = new Intl.NumberFormat('es-CL', {currency: 'CLP', style: 'currency'}).format(num).replace('$', '');
  return result;
}

export function formatToNumber(num: any | undefined): number {
  let result: number = 0;
  if (num !== undefined) {
    if (typeof num === 'string') {
      if (num.indexOf('.0') !== -1) num = num.replace('.0', '');
    }
    result = parseInt(num);
  }
  return result;
}

export function formatToDecimalPercentage(num: number | undefined): string {
  let result: string = '0%';
  if (typeof num === 'number') {
    result = num.toString().replace('.', ',') + '%';
  }
  return result;
}
