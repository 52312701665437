export const types = {
  /* Loading */
  loadType: '[Store] Loading',
  /* Usuario */
  userType: '[Store] User',
  /* Autenticar */
  authType: '[Store] Authentication',
  /* Parameters or Filter */
  paramType: '[Store] Parameters',
  /* Shortening */
  shortType: '[Store] Shortening',
  /* Account Balance */
  accountType: '[Store] Account Balance',
  /* Snackbar */
  snackbarType: '[Store] Snackbar',
};
