import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState, AppThunk} from 'redux/Store/Store';
import {initialStateAuth} from 'redux/Constant/InitialState';
import {toggleLoading} from 'redux/Reducer/Loading/LoadingSlice';
import {types} from 'redux/Types/Types';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import {errorAlert, errorCustomAlert} from 'components/Middleware/Feedback/Alerts';
import {setLoggedInStorage} from 'utils/LocalStorage';
import {IToken, IUser, IAccessTKN, IUserKeycloak} from 'interface/Interface'; //Interfaces
import {UriConfig} from 'config';

const api_rest = UriConfig['api_sql_url'];

export const authSlice = createSlice({
  name: types.authType,
  initialState: {...initialStateAuth},
  reducers: {
    getTokenReducer: (state: any = initialStateAuth, action: PayloadAction<IToken>) => {
      state.token = action.payload;
    },
    getUserReducer: (state: any = initialStateAuth, action: PayloadAction<IUser>) => {
      state.connected = action.payload;
    },
  },
});
export default authSlice.reducer;
export const {getTokenReducer, getUserReducer} = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;

//!Deprecated for implemnement keycloak - 12/04/2023
export const AuthSuccess = (mail: string | undefined) => async (dispatch: any) => {
  try {
    dispatch(toggleLoading());
    const objBody = {
      email: mail,
    };
    await axios
      .post(`${api_rest}/api/auth-role/login`, objBody)
      .then((response) => {
        const status: number = response.status;
        if (status === 201) {
          const data: any = response.data;
          dispatch(getTokenReducer(data));
          const objAccess: IAccessTKN = jwt_decode(data.accessToken);
          if (objAccess.userId) {
            const userId: string = objAccess.userId;
            dispatch(getUser(userId));
          } else {
            console.log('Warning::: [AuthSuccess] Not Found id[', objAccess.userId, ']');
          }
        }
        dispatch(toggleLoading());
      })
      .catch((err) => {
        dispatch(toggleLoading());
        console.log('Error:: [AuthSuccess] ', err);
        errorCustomAlert(err);
        return err;
      });
  } catch (err) {
    dispatch(toggleLoading());
    errorAlert();
    console.log('Error:: [AuthSuccess] ', err);
    return err;
  }
};

//!Deprecated for implemnement keycloak - 12/04/2023
export const getUser =
  (idUser: string): AppThunk =>
  async (dispatch: any) => {
    try {
      dispatch(toggleLoading());
      await axios
        .get(`${api_rest}/api/auth-role/${idUser}`, {
          params: {
            id: idUser,
          },
        })
        .then((response) => {
          const status: number = response.status;
          if (status === 200) {
            const data: any = response.data;
            dispatch(getUserReducer(data));
            setLoggedInStorage();
          }
          dispatch(toggleLoading());
        })
        .catch((err: any) => {
          dispatch(toggleLoading());
          errorCustomAlert(err);
          console.log('Error:: [getUser] ', err);
        });
    } catch (err: unknown) {
      dispatch(toggleLoading());
      errorAlert();
      console.log('Error:: [getUser] ', err);
    }
  };

export const getUserKeycloak =
  (objUserIn: IUserKeycloak): AppThunk =>
  async (dispatch: any) => {
    try {
      dispatch(toggleLoading());
      delete objUserIn.userProfileMetadata; //Delete attribute userProfileMetadata
      // console.log('getUserKeycloak.objUserIn:::[', objUserIn, ']');
      const oTransformUser: IUser = {
        idUser: objUserIn.id,
        mailUser: objUserIn.email || '',
        firstNameUser: objUserIn.firstName || '',
        lastNameUser: objUserIn.lastName || '',
        displayNameUser: `${objUserIn.firstName} ${objUserIn.lastName}` || '',
        username: objUserIn.username || '',
        stateUser: objUserIn.emailVerified || false,
      };
      dispatch(getUserReducer(oTransformUser));
      setLoggedInStorage();
      dispatch(toggleLoading());
    } catch (err: unknown) {
      dispatch(toggleLoading());
      errorAlert();
      console.log('Error:: [getUser] ', err);
    }
  };

// export const isLoggedInKeycloak = () => async (dispatch: any) => {
//   try {
//     console.log(':::isLoggedInKeycloak:::');
//     dispatch(toggleLoading());
//     const _axios: AxiosInstance = axios.create();
//     _axios.interceptors.request.use((config: any) => {
//       console.log(':::isLoggedInKeycloak.interceptors:::');

//       if (KeycloakService.isLoggedIn()) {
//         console.log(':::isLoggedIn:::');
//         const cb = () => {
//           config.headers.Authorization = `Bearer ${KeycloakService.getToken()}`;
//           console.log('isLoggedInKeycloak.KeycloakService.getToken():::[', KeycloakService.getToken(), ']');
//           return Promise.resolve(config);
//         };
//         return KeycloakService.updateToken(cb);
//       }
//     });
//     dispatch(toggleLoading());
//   } catch (err) {
//     dispatch(toggleLoading());
//     errorAlert();
//     console.log('Error:: [isLoggedInKeycloak] ', err);
//     return err;
//   }
// };
