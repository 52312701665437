import {createTheme} from '@mui/material/styles';
import {esES} from '@mui/material/locale';

const theme: object = {
  palette: {
    primary: {
      light: '#6B778C',
      main: '#0052CC',
      dark: '#42526E',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#ffffff',
      main: '#e0e0e0',
      dark: '#aeaeae',
      contrastText: '#000000',
    },
    background: {
      default: '#fafafa',
    },
  },
};

const themeWithLocale = createTheme(theme, esES);

export default themeWithLocale;
