

import {Route, useLocation} from 'react-router-dom';
import {useAppDispatch} from 'hooks/Hooks'; //Hooks
import {cleanAllDevolution} from 'redux/Reducer/Devolution/DevolutionSlice'; //Redux
import {IGuardProps, IMessageFire} from 'interface/Interface'; //Interface
import {setLoggedInStorage, setLoggedOutStorage, setPathNameStorage} from 'utils/LocalStorage'; //Utils
import Home from 'components/Home/Home'; //Component
import Auth from 'components/Auth/AuthAccess';
import {warningAuthRole} from 'components/Middleware/Feedback/Alerts';
import {PathConfig, RoleConfig} from 'config'; //Const Config
import { useSelector } from 'react-redux';
import NotMatch from 'pages/NotMatch';
import NotAllowed from 'pages/NotAllowed';
import { useEffect, useState } from 'react';
//import KeycloakService from 'secure/KeycloakService'; //keycloak



export default AuthGuard;

export interface JarvisTokenEvent {
  eventId: string;
  eventType: string;
  data: JarvisTokenData;
  
}
export interface JarvisTokenData {
  token: string
}

export const NOT_ASSIGNED = 'NOT_ASSIGNED'
export const EVENT_ORIGIN = '*'

export enum UserStatusKeys {
  EXPIRED = 'EXPIRED',
  BLOCKED = 'BLOCKED',
  REJECTED = 'REJECTED',
  AUTHORIZED = 'AUTHORIZED',
  UNAUTHORIZED = 'UNAUTHORIZED'
}

export enum EventTypeKeys {
  authTokenChange = 'authTokenChange',
  applicationLoaded = 'applicationLoaded',
  message = 'message'
}

export class ApplicationLoaderPayload {
  eventId: string = NOT_ASSIGNED
  eventType: string = EventTypeKeys.applicationLoaded
  data: any = null
}

function AuthGuard({children}: IGuardProps) {
  const {pathname} = useLocation();
  const dispatch = useAppDispatch();
  // Path
  const devolutionBalancePath = PathConfig['patDevolutionBalance'];
  const devolutionSummaryPath = PathConfig['patDevolutionSummary'];

  // //const [token, setToken] = useState("asd");
  // useEffect(() => {
  //   console.log('activeeeeeee--!!!!!!!')
    
  //   window.addEventListener("authTokenChange", handleAuthEvent)
  //   return () => {
  //     window.removeEventListener("authTokenChange", handleAuthEvent)
  //   }
  //   //if (KeycloakService.isLoggedIn()) {
  //     //redirectHome(pathname);
  //   //}
  // }, []);

  const handleAuthEvent = (event: any) => {
    console.log('event', event);
    const body = event.data ? event.data : event.detail;
    if (body.eventType === EventTypeKeys.authTokenChange) {
      const status = body.data.token ? UserStatusKeys.AUTHORIZED : UserStatusKeys.REJECTED;
      localStorage.setItem('token', body.data.token)
      window.postMessage('storageChanged', window.location.origin)
    }
  }

  useEffect(() => {
    console.log('activeeeeeee--!!!!!!!')
    window.addEventListener(EventTypeKeys.message, handleAuthEvent)
    return () => {
      window.removeEventListener(EventTypeKeys.message, handleAuthEvent)
    }
  }, [])

  //let isConneted: boolean = false;

  //* Question if logged in keycloak
  /* if (KeycloakService.isLoggedIn()) {
    /*if (!KeycloakService.hasRoleResource(RoleConfig.all)) {
      const userEmail: string = KeycloakService.getEmail();
      const prop: IMessageFire = {
        title: 'Rol de Autenticación',
        html: `Usuario "${userEmail}" no tiene acceso a Sistema Provisiones. <p/>Favor, contactar a Mesa de Ayuda!<p/> <a href="https://falabella-dwp.falabella.cl/dwp/app">https://falabella-dwp.falabella.cl/dwp/app<a/>`,
        timer: 5000,
      };
      warningAuthRole(prop); //Alerta con mensaje de logout
      setTimeout(function () {
        KeycloakService.doLogout();
      }, prop.timer);
    } else {*/
      //isConneted = true;
      setLoggedInStorage(); //create localstorage _loggedIn_2
      setPathNameStorage(pathname); // Setter path in localstorage
      /**
       ** Clean state of store redux
       */
      //* Clean state of Devolution
      if (pathname !== devolutionBalancePath && pathname !== devolutionSummaryPath) {
        dispatch(cleanAllDevolution());
      }
    /*} 
  } else {
    setLoggedOutStorage(); //remove localstorage _loggedIn_
  }*/

  // Send init event to parent for loading finish
  const sendInitEvent = () => {
    const applicationLoader = Object.assign({}, new ApplicationLoaderPayload())
    applicationLoader.eventId = `${Math.random()}${new Date().getTime()}`
    console.log('applicationLoader: ', applicationLoader)
    window.parent.postMessage(applicationLoader, EVENT_ORIGIN)
  }

  useEffect(() => {
    sendInitEvent()
    console.log('pathname: ', pathname);
  }, [])

  const data = localStorage.getItem('token') ?? ''
  if(data !== '') {
    console.log('token: ', data)
    return <Home component={children} />;  
  } 
  else if (window.location.href.indexOf("admin") > -1 || window.location.href.indexOf("osmos") > -1 || window.location.href.indexOf("localhost") > -1){
    return <Home component={children} />;
  }
  return <NotAllowed />;
  

  
}
