export const isLoggedInStorage = (): boolean => {
  let flag: boolean = false;
  if (localStorage.getItem('_loggedIn_') === 'true') {
    flag = true;
  }
  return flag;
};

export const setLoggedInStorage = (): void => {
  if (localStorage.getItem('_loggedIn_') === 'true') return;
  else localStorage.setItem('_loggedIn_', 'true');
  return;
};

export const setLoggedOutStorage = (): void => {
  if (localStorage.getItem('_loggedIn_') === 'true') return localStorage.removeItem('_loggedIn_');
};

export const setPathNameStorage = (_path: string): void => {
  return localStorage.setItem('_pathName_', _path);
};

export const getPathNameStorage = (): string | null => {
  return localStorage.getItem('_pathName_');
};

export const setBarrerTokenStorage = (_tkn: string): void => {
  return localStorage.setItem('_bearerToken_', _tkn);
};

export const getBarrerTokenStorage = (): string | null => {
  return localStorage.getItem('_bearerToken_');
};
