import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from 'axios';
import {initialStateParam} from 'redux/Constant/InitialState';
import {AppThunk, RootState} from 'redux/Store/Store';
import {toggleLoading} from 'redux/Reducer/Loading/LoadingSlice';
import {types} from 'redux/Types/Types';
import {UriConfig} from 'config';

const api_rest = UriConfig['api_sql_url'];

export const paramSlice = createSlice({
  name: types.paramType,
  initialState: {
    ...initialStateParam,
  },
  reducers: {
    getAllCountryList: (state: any = initialStateParam, action: PayloadAction<[]>) => {
      state.listAllCountry = action.payload || [];
    },
    getAllBranchOfficeList: (state: any = initialStateParam, action: PayloadAction<[]>) => {
      state.listAllBranchOffice = action.payload || [];
    },
    getAllBusinessUnitList: (state: any = initialStateParam, action: PayloadAction<[]>) => {
      state.listAllBusinessUnit = action.payload || [];
    },
    getAllClacomList: (state: any = initialStateParam, action: PayloadAction<[]>) => {
      state.listAllClacom = action.payload || [];
    },
    getAllFactorByClacomList: (state: any = initialStateParam, action: PayloadAction<[]>) => {
      state.listAllFactor = action.payload || [];
    },
  },
});

//Action
export const {getAllCountryList, getAllBranchOfficeList, getAllBusinessUnitList, getAllClacomList, getAllFactorByClacomList} = paramSlice.actions;

//State-Reducer
export const selectParam = (state: RootState) => state.parameter;

//Reducer
export default paramSlice.reducer;

//Negocio
//Mantenedor Lista Paises
export const getAllCountries = () => (dispatch: any) => {
  dispatch(toggleLoading());
  axios
    .get(`${api_rest}/api/generic/country`)
    .then((response) => {
      dispatch(getAllCountryList(response.data));
      dispatch(toggleLoading());
    })
    .catch((error) => {
      dispatch(toggleLoading());
      console.log(error);
    });
};
//Mantenedor Lista Unidad de Negocio
export const getAllBusinessUnit = (): AppThunk => (dispatch) => {
  // dispatch(toggleLoading());
  axios
    .get(`${api_rest}/api/generic/business-unit`)
    .then((response) => {
      dispatch(getAllBusinessUnitList(response.data));
      // dispatch(toggleLoading());
    })
    .catch((error) => {
      // dispatch(toggleLoading());
      console.log(error);
    });
};
//Mantenedor Lista Tiendas
export const getAllBranchOffice = (): AppThunk => (dispatch) => {
  // dispatch(toggleLoading());
  axios
    .get(`${api_rest}/api/generic/branch-office`)
    .then((response) => {
      dispatch(getAllBranchOfficeList(response.data));
      // dispatch(toggleLoading());
    })
    .catch((error) => {
      // dispatch(toggleLoading());
      console.log(error);
    });
};
//Mantenedor Clacom
export const getAllClacom = (): AppThunk => (dispatch) => {
  axios
    .get(`${api_rest}/api/clacom`)
    .then((response) => {
      dispatch(getAllClacomList(response.data));
      // dispatch(toggleLoading());
    })
    .catch((error) => {
      // dispatch(toggleLoading());
      console.log(error);
    });
};
//Mantenedor Factor
export const getAllFactorByClacom = (idClacom): AppThunk => (dispatch) => {
  axios
    .get(`${api_rest}/api/obsolescence/getAllFactorByClacom`, {params: {idClacom: idClacom}})
    .then((response) => {
      dispatch(getAllFactorByClacomList(response.data));
      // dispatch(toggleLoading());
    })
    .catch((error) => {
      // dispatch(toggleLoading());
      console.log(error);
    });
};