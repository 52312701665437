import {ThemeProvider} from '@emotion/react';
import {BrowserRouter} from 'react-router-dom';
//Components
import BackdropSimple from 'components/Middleware/Loader/BackdropSimple';
import Snackbar from 'components/Middleware/Feedback/Snackbar';
// import BackdropWithProgress from 'components/Loader/BackdropWithProgress';
import themeWithLocale from 'theme/Theme';
import Router from 'router/Routes';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={themeWithLocale}>
        <BackdropSimple />
        <Snackbar />
        {/* <BackdropWithProgress /> */}
        <Router />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
