import {IAccountingSeatHead, ICustomizedDialogProps, IFormDevolutionBalance} from 'interface/Interface';

export const initialFormValidDevolution: IFormDevolutionBalance = {
  country: '',
  businessUnit: '',
  typeAccount: '',
  period: '',
};

export const initialFormErrorDevolution: IFormDevolutionBalance = {
  country: ' ',
  businessUnit: ' ',
  typeAccount: ' ',
};

export const initialDialogProp: ICustomizedDialogProps = {
  props: {
    title: '',
    message: '',
  },
};

export const initialAccountingSeatHeadProp: IAccountingSeatHead = {
  accountSeat: {
    income: {
      item: '',
      factorAverage: 0,
      amountToConsider: 0,
      imputedAmount: 0,
      accountMust: '',
      accountToHave: '',
      accountDate: '',
    },
    cost: {
      item: '',
      factorAverage: 0,
      amountToConsider: 0,
      imputedAmount: 0,
      accountMust: '',
      accountToHave: '',
      accountDate: '',
    },
  },
};
