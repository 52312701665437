import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {IMessageToastFire, IMessageFire} from 'interface/Interface';

const MySwal = withReactContent(Swal);

export const successAlert = () => {
  MySwal.fire({
    icon: 'success',
    title: 'Plantilla descargada exitosamente',
    showConfirmButton: false,
    timer: 2500,
  });
};

export const errorAlert = () => {
  MySwal.fire({
    icon: 'error',
    title: 'Problemas con el servidor.',
    text: 'Inténtalo más tarde!',
    showConfirmButton: false,
    timer: 2500,
  });
};

export const successAlertResponseFile = (obj: any) => {
  MySwal.fire({
    icon: 'success',
    title: 'Se ha realizado la carga exitosamente',
    html:
      `Factores cargados: ${obj.OkProcess} <br/>` +
      `Factores no cargados: ${obj.NokProcess} <br/>` +
      `Total factores cargados: ${obj.Total} <br/>`,
    showConfirmButton: true,
    confirmButtonText: 'OK',
  });
};

export const messageCustomToast = (objMessage: IMessageToastFire) => {
  const Toast = Swal.mixin({
    toast: true,
    position: objMessage.position,
    showConfirmButton: false,
    timer: objMessage.timer,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: objMessage.icon,
    title: objMessage.title,
  });

  //Call
  // const objTetst: IMessageToast = {
  //   icon: 'success',
  //   title: 'Iniciado sesión con éxito',
  //   position: 'bottom-end',
  //   timer: 5000,
  // };
  // messageCustomToast(objTetst);
};

export const errorCustomAlert = (objError: IMessageFire) => {
  MySwal.fire({
    icon: 'error',
    title: `Error: Code ${objError.statusCode}`,
    text: objError.message,
    showConfirmButton: false,
    timer: 2500,
  });
};

export const infoCustomAlert = (objInfo: IMessageFire) => {
  MySwal.fire({
    icon: 'info',
    title: `Info: ${objInfo.title}`,
    text: objInfo.message,
    showConfirmButton: false,
    timer: objInfo.timer,
  });
};

export const warningAuthRole = (objInfo: IMessageFire) => {
  MySwal.fire({
    icon: 'warning',
    title: `<strong>${objInfo.title}</strong>`,
    text: objInfo.message,
    html: objInfo.html,
    showConfirmButton: false,
    timer: objInfo.timer,
  });
};
