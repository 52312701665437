import {createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import {
  initialFilterApiDevolution,
  initialFilterDisplayDevolution,
  initialStateDevolution,
  initialStateSetSnackbar,
} from 'redux/Constant/InitialState';
import {AppThunk, RootState} from 'redux/Store/Store';
import {types} from 'redux/Types/Types';
import {toggleLoading} from 'redux/Reducer/Loading/LoadingSlice';
import {setOpenSnackbar} from 'redux/Reducer/Snackbar/SnackbarSlice';
import {
  IBodyDevolutionCalc,
  IDevolutionBalanceResponse,
  IDevolutionBalanceResponseTable,
  IDevolutionSummaryAuth,
  IForm519DevolutionBalance,
  ISnackbar,
} from 'interface/Interface';
import {errorColor, successColor} from 'components/Middleware/Feedback/Snackbar';
import {UriConfig} from 'config';
import {initialFormValidDevolution} from 'constant/InitialForm';
import {formatToNumber} from 'utils/FormatFnt';

//borrar ini
// import accountBalanceMock from 'mocks/accountBalanceNewMock.json';
// import customerReturnMock from 'mocks/customerReturnMock.json';
//borrar fin

const api_rest = UriConfig['api_sql_url'];
const excludeCountry = UriConfig['exclude_country'];
const token = localStorage.getItem('token');
const headerConfig = {headers: {
  Authorization: 'Bearer ' + token //the token is a variable which holds the token
}}
//Negocio
export const getAllCountry = (): AppThunk => (dispatch: any) => {

  console.log('exclude country', excludeCountry)
  axios
    .get(`${api_rest}/api/generic/country`, headerConfig)
    .then((response) => {
      const filteredCountries = response.data.filter(country => country.codeCountry !== excludeCountry);
      dispatch(getDevolutionBalanceCountry(filteredCountries));
    })
    .catch((error) => {
      console.log(error);
    });
};


export const getBusinessUnitByCountry =
  (idCountry: string): AppThunk =>
  (dispatch: any) => {
    dispatch(toggleLoading());
    axios
      .get(`${api_rest}/api/factor/filter`, {
        params: {
          idCountry: idCountry,
        },
        headers: {
          Authorization: 'Bearer ' + token //the token is a variable which holds the token
        }
      })
      .then((response) => {
        dispatch(getDevolutionBalanceBusinessUnit(response.data));
        dispatch(toggleLoading());
      })
      .catch((error) => {
        dispatch(toggleLoading());
        console.log(error);
      });
  };

export const setFilterParameterForm =
  (filter: any): AppThunk =>
  (dispatch: any) => {
    dispatch(setDevolutionBalanceFilterForm(filter));
  };

export const setFilterParameterApi =
  (filter: any): AppThunk =>
  (dispatch: any) => {
    dispatch(setDevolutionBalanceFilterApi(filter));
  };

export const setFilterParameterDisplay =
  (filter: any): AppThunk =>
  (dispatch: any) => {
    dispatch(setDevolutionBalanceFilterDisplay(filter));
  };

const setteringData = (
  response: IDevolutionBalanceResponse[],
  param: IForm519DevolutionBalance
): IDevolutionBalanceResponseTable[] => {
  console.log('%c setteringData', 'color: green', response);
  let arrDevResponse: IDevolutionBalanceResponseTable[] = [];
  if (response.length > 0) {
    response.forEach((element: any, index) => {
      let objTemp: IDevolutionBalanceResponseTable = {
        id: index + 1,
        Society: param.companyCode,
        MonthYear: param.monthYear,
        GLAccount: element.GLAccount,
        CostCenter: element.CostCenter,
        ProfitCenter: element.ProfitCenter,
        FunctionalArea: element.FunctionalArea,
        Order: element.Order,
        Segment: element.Segment,
        Currency: element.Currency,
        InitialBalances: element.InitialBalances && formatToNumber(element.InitialBalances),
        FinalBalances: element.FinalBalances && formatToNumber(element.FinalBalances),
        DBMovements: element.DBMovements && formatToNumber(element.DBMovements),
        CRMovements: element.CRMovements && formatToNumber(element.CRMovements),
      };
      arrDevResponse.push(objTemp);
    });
    console.log('arrDevResponse:::[', arrDevResponse, ']');
  }
  return arrDevResponse;
};

export const getDataAccountBalance =
  (inObj: IForm519DevolutionBalance): AppThunk =>
  (dispatch: any) => {
    dispatch(toggleLoading());

    let objSnackbar: ISnackbar = initialStateSetSnackbar;
    axios
      .post(`${api_rest}/api/account-balance/filter`, inObj, headerConfig)
      .then((response) => {
        const resData: IDevolutionBalanceResponse[] = response.data;
        const devResponse: any = setteringData(resData, inObj);
        console.log('resData-->[', resData, ']');
        console.log('devResponse-->[', devResponse, ']');

        //Satisfied
        if (resData.length > 0) {
          dispatch(getDevolutionBalance(devResponse));
          objSnackbar = {
            isOpen: true,
            message: 'Resultado de búsqueda satisfactorio',
            severity: successColor,
            timeOut: initialStateSetSnackbar.timeOut,
            anchorOrigin: initialStateSetSnackbar.anchorOrigin,
          };
        } else {
          //Error not found or other
          objSnackbar = {
            isOpen: true,
            message: 'No se encontraron datos',
            severity: errorColor,
            timeOut: initialStateSetSnackbar.timeOut,
            anchorOrigin: initialStateSetSnackbar.anchorOrigin,
          };
        }
        dispatch(setOpenSnackbar(objSnackbar));
        dispatch(toggleLoading());
      })
      .catch((error) => {
        if (error.response.status=== 404){
          objSnackbar = {
            isOpen: true,
            message: 'No se encontraron Datos',
            severity: errorColor,
            timeOut: initialStateSetSnackbar.timeOut,
            anchorOrigin: initialStateSetSnackbar.anchorOrigin,
          };
        } else {
        objSnackbar = {
          isOpen: true,
          message: error.code,
          severity: errorColor,
          timeOut: initialStateSetSnackbar.timeOut,
          anchorOrigin: initialStateSetSnackbar.anchorOrigin,
        };
        }
        dispatch(setOpenSnackbar(objSnackbar));
        dispatch(toggleLoading());
        console.log(error);
        //borrar ini
        // console.log('getDataAccountBalance-Mock Starting...');
        // const temp: any = accountBalanceMock;
        // dispatch(getDevolutionBalance(temp));
        //borrar fin
      });
  };

export const postDevolutionCalc =
  (inObj: IForm519DevolutionBalance): AppThunk =>
  (dispatch: any) => {
    dispatch(toggleLoading());

    const newInObj: IBodyDevolutionCalc = {
      country: inObj.country,
      companyCode: inObj.companyCode,
      monthYear: inObj.monthYear,
      ledger: inObj.ledger,
      currencyType: inObj.currencyType,
    };

    let objSnackbar: ISnackbar = initialStateSetSnackbar;
    axios
      .post(`${api_rest}/api/devolution/calc`, newInObj, headerConfig)
      .then((response) => {
        dispatch(getDevolutionSummary(response.data));
        objSnackbar = {
          isOpen: true,
          message: 'Resultado de cálculo satisfatorio',
          severity: successColor,
          timeOut: initialStateSetSnackbar.timeOut,
          anchorOrigin: initialStateSetSnackbar.anchorOrigin,
        };
        dispatch(setOpenSnackbar(objSnackbar));
        dispatch(toggleLoading());
      })
      .catch((error) => {
        objSnackbar = {
          isOpen: true,
          message: error.message,
          severity: errorColor,
          timeOut: initialStateSetSnackbar.timeOut,
          anchorOrigin: initialStateSetSnackbar.anchorOrigin,
        };
        dispatch(setOpenSnackbar(objSnackbar));
        dispatch(toggleLoading());
        console.log(error);
        //borrar ini
        // console.log('postDevolutionCalc-Mock Starting...');
        // const temp: any = customerReturnMock;
        // dispatch(getDevolutionSummary(temp));
        //borrar fin
      });
  };

export const postDevolutionAuth =
  (inObj: IDevolutionSummaryAuth): AppThunk =>
  (dispatch: any) => {
    dispatch(toggleLoading());
    let objSnackbar: ISnackbar = initialStateSetSnackbar;

    axios
      .post(`${api_rest}/api/devolution/auth`, inObj, headerConfig)
      .then((response) => {
        const data: any = response.data;
        dispatch(setDevolutionAuth(data));
        objSnackbar = {
          isOpen: true,
          message: 'Se autorizo y almaceno satisfatoriamente',
          severity: successColor,
          timeOut: initialStateSetSnackbar.timeOut,
          anchorOrigin: initialStateSetSnackbar.anchorOrigin,
        };
        dispatch(setOpenSnackbar(objSnackbar));
        dispatch(toggleLoading());
      })
      .catch((error) => {
        if (error.response.status=== 400){
          objSnackbar = {
            isOpen: true,
            message: 'Error al autorizar',
            severity: errorColor,
            timeOut: initialStateSetSnackbar.timeOut,
            anchorOrigin: initialStateSetSnackbar.anchorOrigin,
          };
        } ;
        if (error.response.status=== 404){
          objSnackbar = {
            isOpen: true,
            message: 'Error de Datos no encontrados ',
            severity: errorColor,
            timeOut: initialStateSetSnackbar.timeOut,
            anchorOrigin: initialStateSetSnackbar.anchorOrigin,
          };
        }
        if (error.response.status=== 500){
          objSnackbar = {
            isOpen: true,
            message: 'Error de comunicación con el Servidor, Favor comuníquese con el Administrador',
            severity: errorColor,
            timeOut: initialStateSetSnackbar.timeOut,
            anchorOrigin: initialStateSetSnackbar.anchorOrigin,
          };
        } else {
          objSnackbar = {
            isOpen: true,
            message: error.message,
            severity: errorColor,
            timeOut: initialStateSetSnackbar.timeOut,
            anchorOrigin: initialStateSetSnackbar.anchorOrigin,
          };
        }
        dispatch(setOpenSnackbar(objSnackbar));
        dispatch(toggleLoading());
        console.log(error);
      });

  };

export const cleanDevolution = () => (dispatch: any) => {
  dispatch(cleanAllDevolution());
};

//Reducers
export const devolutionSlice = createSlice({
  name: types.accountType,
  initialState: {
    ...initialStateDevolution,
  },
  reducers: {
    getDevolutionBalanceCountry: (state: any = initialStateDevolution, action: any) => {
      state.listCountry = action.payload || [];
    },
    getDevolutionBalanceBusinessUnit: (state: any = initialStateDevolution, action: any) => {
      state.listBusinessUnit = action.payload || [];
    },
    setDevolutionBalanceFilterForm: (state: any = initialFormValidDevolution, action: any) => {
      state.filterForm = action.payload;
    },
    setDevolutionBalanceFilterApi: (state: any = initialFilterApiDevolution, action: any) => {
      state.filterApi = action.payload;
    },
    setDevolutionBalanceFilterDisplay: (state: any = initialFilterDisplayDevolution, action: any) => {
      state.filterDisplay = action.payload;
    },
    getDevolutionBalance: (state: any = initialStateDevolution, action: any) => {
      state.listDevolutionBalance = action.payload || [];
    },
    getDevolutionSummary: (state: any = initialStateDevolution, action: any) => {
      state.listDevolutionSummary = action.payload || [];
    },
    setDevolutionAuth: (state: any = initialStateDevolution, action: any) => {
      state.devolutionAuth = action.payload;
    },
    toggleReturn: (state: any = initialStateDevolution, action: any) => {
      state.flagReturn = action.payload.flagReturn;
    },
    cleanAllDevolution: (state: any = initialStateDevolution) => {
      state.filterForm = initialStateDevolution.filterForm;
      state.filterApi = initialStateDevolution.filterApi;
      state.filterDisplay = initialStateDevolution.filterDisplay;
      state.listDevolutionBalance = initialStateDevolution.listDevolutionBalance;
      state.listDevolutionSummary = initialStateDevolution.listDevolutionSummary;
      state.devolutionAuth = initialStateDevolution.devolutionAuth;
      state.flagReturn = initialStateDevolution.flagReturn;
    },
  },
});

//Action
export const {
  getDevolutionBalanceCountry,
  getDevolutionBalanceBusinessUnit,
  setDevolutionBalanceFilterForm,
  setDevolutionBalanceFilterApi,
  setDevolutionBalanceFilterDisplay,
  getDevolutionBalance,
  getDevolutionSummary,
  setDevolutionAuth,
  toggleReturn,
  cleanAllDevolution,
} = devolutionSlice.actions;

//State-Reducer
export const selectDevolution = (state: RootState) => state.devolution;
//Expo. Reducer
export default devolutionSlice.reducer;
